import React from 'react';
import { SvgIcon } from '@mui/material';

const SvgTransit = (props) => (
  <SvgIcon {...props} title="Transit">
    <svg
      viewBox="0 0 12 13"
      fill="#000"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6 .333C3.333.333.667.667.667 3v6.333A2.336 2.336 0 0 0 3 11.667l-.807.54a.432.432 0 0 0 .24.793h7.133a.432.432 0 0 0 .24-.793L9 11.667a2.336 2.336 0 0 0 2.333-2.334V3C11.333.667 8.947.333 6 .333ZM3.667 9.667c-.554 0-1-.447-1-1 0-.554.446-1 1-1 .553 0 1 .446 1 1 0 .553-.447 1-1 1Zm1.666-4H2v-2h3.333v2Zm3 4c-.553 0-1-.447-1-1 0-.554.447-1 1-1 .553 0 1 .446 1 1 0 .553-.447 1-1 1Zm1.667-4H6.667v-2H10v2Z"
        fill={props.fill}
      />
    </svg>
  </SvgIcon>
);

export default SvgTransit;
