import React from 'react';
import { SvgIcon } from '@mui/material';
const SvgNearMe = (props) => (
  <SvgIcon {...props} title="NearMe">
    <svg
      viewBox="0 0 16 16"
      fill="black"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#nearMe_svg__a)">
        <path
          d="M11.513 4.487 8.687 11.24l-.88-2.28-.214-.553-.546-.214-2.287-.886 6.753-2.82ZM14 2 2 7.02v.653L6.56 9.44 8.32 14h.653L14 2Z"
          fill={props.fill}
        />
      </g>
      <defs>
        <clipPath id="nearMe_svg__a">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  </SvgIcon>
);
export default SvgNearMe;
