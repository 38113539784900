import { SxProps } from '@mui/material';
import { lightTheme } from '../../theme';

const { palette, spacing } = lightTheme;

const tabStyle = {
  padding: '0.5rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '0.25rem',
  borderRadius: '0.25rem',
  background: palette.grey['50'],
  cursor: 'pointer',
  '& p': {
    color: palette.grey.main
  }
};

const styles: Record<string, SxProps> = {
  container: {
    margin: '0 auto',
    width: {
      xs: '100%',
      md: '44rem'
    },
    minHeight: {
      /* Prevents full page height change on search input focus animation */
      xs: '17.2rem',
      sm: '15.7rem'
    }
  },
  tabsContainer: {
    padding: spacing(0.5),
    background: palette.primary[400],
    border: `0.063rem solid ${palette.secondary['600']}`,
    borderTopWidth: 0,
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 0,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    overflow: 'hidden',

    '.MuiTabs-flexContainer': {
      gap: '0.25rem',
      justifyContent: 'space-between'
    },

    '.MuiTabs-scrollButtons': {
      width: '1.875rem',
      height: {
        xs: '2.25rem',
        ss: '2.125rem'
      },
      '&::after': {
        content: '""',
        width: '100%',
        height: '100%',
        top: '0',
        left: '0',
        background:
          'linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(230,246,246,1) 100%)'
      },
      svg: {
        fontSize: '2.5rem'
      }
    },
    '.MuiTabs-scrollButtons.Mui-disabled': {
      width: '0'
    }
  },

  internalTabsContainer: {
    display: 'flex',
    maxHeight: '0px',
    transition: 'max-height 0.5s ease-in-out',
    boxSizing: 'border-box',
    overflow: 'hidden',
    gap: spacing(0.5),
    '& > .MuiTabs-root': {
      pt: '0.75rem'
    },
    '&.visible': {
      maxHeight: '10rem' // does not matter
    }
  },

  tabIcon: {
    display: 'flex',
    fontSize: {
      xs: '1rem',
      ss: '1.25rem'
    },
    color: palette.grey.main
  },

  tabLabel: {
    fontSize: {
      xs: '0.75rem',
      ss: '0.875rem'
    },
    lineHeight: '1.25rem',
    color: palette.primary[300],
    fontWeight: 500
  },

  tab: {
    ...tabStyle,
    '& svg': {
      fontSize: '1rem'
    }
  },

  selectedTab: {
    ...tabStyle,
    backgroundColor: palette.primary.main,
    border: `0.063rem solid ${palette.primary.main}`,
    '& p': {
      color: palette.grey[50]
    },
    '& svg': {
      color: palette.grey[50],
      fill: palette.grey[50],
      fontSize: '1rem'
    }
  },

  inverseTabsContainer: {
    padding: '0.5rem',
    background: palette.grey[50],
    display: 'flex',
    borderRadius: '0.5rem',
    justifyContent: 'space-around'
  },

  inverseTabLabel: {
    fontSize: '0.875rem',
    fontWeight: 800,
    lineHeight: '1.25rem',
    color: palette.primary[300]
  },

  inverseTab: {
    ...tabStyle
  },

  inverseSelectedTab: {
    ...tabStyle,
    backgroundColor: palette.primary[400],
    '& p': {
      color: palette.primary.main
    }
  },

  closeBtn: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
    height: '2.5rem',
    '& button': {
      padding: 0,
      justifyContent: 'flex-end',
      minWidth: '0',
      '& svg': {
        color: palette.grey.main,
        width: '2rem'
      }
    }
  },

  centralBoxStyles: {
    width: '100%',
    backgroundColor: 'white',
    borderRadius: '0.25rem',
    padding: '0.5rem',
    height: '3.75rem',
    border: `0.063rem solid ${palette.secondary['600']}`
  },

  footerLine: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '0.5rem',
    width: '100%',
    color: palette.grey.main
  },

  footerMsg: {
    fontSize: {
      xs: '0.75rem',
      sm: '1rem'
    }
  },

  // Mobile Text
  mobileTitleBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '0.5rem'
  },

  mobileTitleHomePage: {
    display: 'none'
  },

  mobileTitle: {
    fontSize: '1.375rem',
    fontWeight: '900',
    lineHeight: '2rem',
    color: palette.primary.main
  },

  // RenderLinksButtons
  linkButtonsBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '0.5rem',
    width: '100%',
    height: '100%',

    '& a': {
      textDecoration: 'none',
      '& button': {
        padding: '0',
        height: '2.5rem',
        fontSize: {
          xs: '0.65rem',
          sm: '1rem'
        }
      }
    }
  },

  exclaimationIcon: {
    transform: 'rotateZ(180deg)',
    fontSize: '1.25rem'
  },
  scrollableTabs: {
    minHeight: '1.5rem'
  },
  valuationBanner: {
    background: palette.primary.main,
    color: palette.common.white,
    paddingX: {
      xs: spacing(0.5),
      sm: spacing(1)
    },
    paddingY: spacing(1),
    display: 'flex',
    flexDirection: {
      xs: 'column',
      sm: 'row'
    },
    gap: spacing(0.5),
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottomLeftRadius: '6px',
    borderBottomRightRadius: '6px'
  },
  valuationBannerTitle: {
    textAlign: {
      xs: 'center',
      sm: 'left'
    }
  }
};

export default styles;
