import { Tab as MuiTab, type SxProps } from '@mui/material';
import { tabStyles } from './styles';

type TabsProps = React.ComponentProps<typeof MuiTab>;

export default function Tab(props: TabsProps) {
  const { sx } = props;
  const styles: SxProps = sx ? { ...tabStyles, ...sx } : tabStyles;

  return <MuiTab {...props} sx={styles} />;
}
