import React from 'react';
import MapIcon from '@mui/icons-material/Map';
import PlaceIcon from '@mui/icons-material/Place';
import Transit from '../../icons/Transit';
import School from '../../icons/School';
import Gesture from '../../icons/Gesture';
import Clock from '../../icons/Clock';
import NearMe from '../../icons/NearMe';

const tabsDataDesktop = [
  {
    label: 'Location',
    value: 'location',
    tag: 'location',
    icon: React.createElement(PlaceIcon),
    msg: 'Search by location name, e.g ‘W4’ or Brixton’',
    subLinks: []
  },
  {
    label: 'Map',
    value: 'map',
    tag: 'map',
    icon: React.createElement(MapIcon),
    msg: 'Browse a map of London & Surrey',
    href: (tab: string) => `/${tab.toLowerCase()}/map`,
    subLinks: [
      {
        id: '01',
        tabName: 'Buy',
        linkTab: '/properties-for-sale/map'
      },
      {
        id: '02',
        tabName: 'Long Let',
        linkTab: '/properties-to-rent/map'
      },
      {
        id: '03',
        tabName: 'Short Let',
        linkTab: '/short-let-properties/map'
      },
      {
        id: '04',
        tabName: 'Auction',
        linkTab: '/properties-for-auction/map'
      }
    ]
  },
  {
    label: 'Draw',
    value: 'draw',
    tag: 'poly',
    icon: React.createElement(Gesture),
    msg: 'Browse a map of London & Surrey',
    href: (tab: string) => `/${tab.toLowerCase()}/poly`,
    subLinks: [
      {
        id: '01',
        tabName: 'Buy',
        linkTab: '/properties-for-sale/poly'
      },
      {
        id: '02',
        tabName: 'Long Let',
        linkTab: '/properties-to-rent/poly'
      },
      {
        id: '03',
        tabName: 'Short Let',
        linkTab: '/short-let-properties/poly'
      },
      {
        id: '04',
        tabName: 'Auction',
        linkTab: '/properties-for-auction/poly'
      }
    ]
  },
  {
    label: 'Travel Time',
    value: 'travel-time',
    tag: 'travel',
    icon: React.createElement(Clock),
    msg: 'Browse a map of London & Surrey',
    href: (tab: string) => `/${tab.toLowerCase()}/travel`,
    subLinks: [
      {
        id: '01',
        tabName: 'Buy',
        linkTab: '/properties-for-sale/travel'
      },
      {
        id: '02',
        tabName: 'Long Let',
        linkTab: '/properties-to-rent/travel'
      },
      {
        id: '03',
        tabName: 'Short Let',
        linkTab: '/short-let-properties/travel'
      },
      {
        id: '04',
        tabName: 'Auction',
        linkTab: '/properties-for-auction/travel'
      }
    ]
  },
  {
    label: 'School',
    value: 'school',
    tag: 'school',
    icon: React.createElement(School),
    msg: 'Browse a map of London & Surrey',
    href: (tab: string) => `/${tab.toLowerCase()}/school`,
    subLinks: [
      {
        id: '01',
        tabName: 'Buy',
        linkTab: '/properties-for-sale/school'
      },
      {
        id: '02',
        tabName: 'Long Let',
        linkTab: '/properties-to-rent/school'
      },
      {
        id: '03',
        tabName: 'Short Let',
        linkTab: '/short-let-properties/school'
      },
      {
        id: '04',
        tabName: 'Auction',
        linkTab: '/properties-for-auction/school'
      }
    ]
  },
  {
    label: 'Station',
    value: 'station',
    tag: 'tube',
    icon: React.createElement(Transit),
    msg: 'Browse a map of London & Surrey',
    href: (tab: string) => `/${tab.toLowerCase()}/tube`,
    subLinks: [
      {
        id: '01',
        tabName: 'Buy',
        linkTab: '/properties-for-sale/tube'
      },
      {
        id: '02',
        tabName: 'Long Let',
        linkTab: '/properties-to-rent/tube'
      },
      {
        id: '03',
        tabName: 'Short Let',
        linkTab: '/short-let-properties/tube'
      },
      {
        id: '04',
        tabName: 'Auction',
        linkTab: '/properties-for-auction/tube'
      }
    ]
  },
  {
    label: 'Near me',
    value: 'nearMe',
    tag: 'nearMe',
    icon: React.createElement(NearMe),
    msg: 'Browse a map of London & Surrey',
    href: (tab: string) => `/${tab.toLowerCase()}/`,
    subLinks: [
      {
        id: '01',
        tabName: 'Buy',
        linkTab: '/properties-for-sale/'
      },
      {
        id: '02',
        tabName: 'Long Let',
        linkTab: '/properties-to-rent/'
      },
      {
        id: '03',
        tabName: 'Short Let',
        linkTab: '/short-let-properties/'
      },
      {
        id: '04',
        tabName: 'Auction',
        linkTab: '/properties-for-auction/'
      }
    ]
  }
];

const tabsDataMobile = [
  {
    label: 'Location',
    value: 'location',
    tag: 'location',
    icon: React.createElement(PlaceIcon),
    msg: 'Search by location name, e.g ‘W4’ or Brixton’',
    subLinks: []
  },
  {
    label: 'Map',
    value: 'map',
    tag: 'map',
    icon: React.createElement(MapIcon),
    msg: 'Browse a map of London & Surrey',
    href: (tab: string) => `/${tab.toLowerCase()}/map`,
    subLinks: [
      {
        id: '01',
        tabName: 'Buy',
        linkTab: '/properties-for-sale/map'
      },
      {
        id: '02',
        tabName: 'Long Let',
        linkTab: '/properties-to-rent/map'
      },
      {
        id: '03',
        tabName: 'Short Let',
        linkTab: '/short-let-properties/map'
      },
      {
        id: '04',
        tabName: 'Auction',
        linkTab: '/properties-for-auction/map'
      }
    ]
  },
  {
    label: 'Travel Time',
    value: 'travel-time',
    tag: 'travel',
    icon: React.createElement(Clock),
    msg: 'Browse a map of London & Surrey',
    href: (tab: string) => `/${tab.toLowerCase()}/travel`,
    subLinks: [
      {
        id: '01',
        tabName: 'Buy',
        linkTab: '/properties-for-sale/travel'
      },
      {
        id: '02',
        tabName: 'Long Let',
        linkTab: '/properties-to-rent/travel'
      },
      {
        id: '03',
        tabName: 'Short Let',
        linkTab: '/short-let-properties/travel'
      },
      {
        id: '04',
        tabName: 'Auction',
        linkTab: '/properties-for-auction/travel'
      }
    ]
  },
  {
    label: 'School',
    value: 'school',
    tag: 'school',
    icon: React.createElement(School),
    msg: 'Browse a map of London & Surrey',
    href: (tab: string) => `/${tab.toLowerCase()}/school`,
    subLinks: [
      {
        id: '01',
        tabName: 'Buy',
        linkTab: '/properties-for-sale/school'
      },
      {
        id: '02',
        tabName: 'Long Let',
        linkTab: '/properties-to-rent/school'
      },
      {
        id: '03',
        tabName: 'Short Let',
        linkTab: '/short-let-properties/school'
      },
      {
        id: '04',
        tabName: 'Auction',
        linkTab: '/properties-for-auction/school'
      }
    ]
  },
  {
    label: 'Station',
    value: 'station',
    tag: 'tube',
    icon: React.createElement(Transit),
    msg: 'Browse a map of London & Surrey',
    href: (tab: string) => `/${tab.toLowerCase()}/tube`,
    subLinks: [
      {
        id: '01',
        tabName: 'Buy',
        linkTab: '/properties-for-sale/tube'
      },
      {
        id: '02',
        tabName: 'Long Let',
        linkTab: '/properties-to-rent/tube'
      },
      {
        id: '03',
        tabName: 'Short Let',
        linkTab: '/short-let-properties/tube'
      },
      {
        id: '04',
        tabName: 'Auction',
        linkTab: '/properties-for-auction/tube'
      }
    ]
  },
  {
    label: 'Near me',
    value: 'nearMe',
    tag: 'nearMe',
    icon: React.createElement(NearMe),
    msg: 'Browse a map of London & Surrey',
    href: (tab: string) => `/${tab.toLowerCase()}/`,
    subLinks: [
      {
        id: '01',
        tabName: 'Buy',
        linkTab: '/properties-for-sale/'
      },
      {
        id: '02',
        tabName: 'Long Let',
        linkTab: '/properties-to-rent/'
      },
      {
        id: '03',
        tabName: 'Short Let',
        linkTab: '/short-let-properties/'
      },
      {
        id: '04',
        tabName: 'Auction',
        linkTab: '/properties-for-auction/'
      }
    ]
  }
];

export { tabsDataDesktop, tabsDataMobile };
