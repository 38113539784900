import { Tabs as MuiTabs, type SxProps } from '@mui/material';
import { tabsStyles } from './styles';

type TabsProps = React.ComponentProps<typeof MuiTabs>;

export default function Tabs(props: TabsProps) {
  const { sx } = props;
  const styles: SxProps = sx ? { ...tabsStyles, ...sx } : tabsStyles;

  return <MuiTabs {...props} sx={styles} />;
}
