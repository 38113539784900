import React from 'react';
import { SvgIcon } from '@mui/material';

const SvgGesture = (props) => (
  <SvgIcon {...props} title="Gesture">
    <svg
      viewBox="0 0 12 12"
      fill="#000"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M.58 2.02a.836.836 0 0 0 1.147.007c.2-.187.36-.294.473-.247.333.133 0 .687-.2 1.013-.353.594-3.487 4.434-1.12 6.194 1.7 1.266 3.18-.64 3.907-1.54.806-.994 1.886-2.294 2.72-2.294 1.086 0 1.1.674 1.173 1.194-2.52.426-3.587 2.446-3.587 3.58 0 1.133.96 2.073 2.14 2.073 1.087 0 2.86-.9 3.127-4.08h.807a.834.834 0 0 0 0-1.667h-.814c-.1-1.1-.726-2.8-2.686-2.8-1.5 0-2.787 1.274-3.294 1.894C2.587 7.6 2.233 7.72 2 7.72c-.3 0-.48-.553-.24-1.28.693-2.16 2.207-2.847 2.207-4.533C3.967.46 2.873 0 2.293 0 1.78 0 1.227.227.607.8A.84.84 0 0 0 .58 2.02Zm6.673 8.347c-.966 0-.46-1.827 1.42-2.32-.2 1.793-.953 2.32-1.42 2.32Z"
        fill={props.fill}
      />
    </svg>
  </SvgIcon>
);

export default SvgGesture;
