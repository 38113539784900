import React, { useState, useEffect } from 'react';
import { Box, Typography, useMediaQuery } from '@mui/material';
import Link from 'next/link';
import { Tabs, Tab } from '../../components/Tabs/index';
import { SearchProperty } from '../SearchPropertyAutoComplete';
import { tabsDataDesktop, tabsDataMobile } from './propertySearchTabs';
import getTabStyles from './utility';
import styles from './styles';
import { getPropertiesNearByCord } from '../../utils/httpRequest';
import {
  getLastSearchHistory,
  setLastSearchHistory
} from '../SearchPropertyAutoComplete/utility';
import ValuationBanner from './ValuationBanner';

export interface Tab {
  label: string;
  value: string;
  tag: string;
  icon: React.ReactNode | JSX.Element;
  msg: string;
  subLinks: {
    id: string;
    tabName: string;
    linkTab: string;
  }[];
  href?: (tab: string) => string;
}

export interface LibSearchPropertyBoxProps {
  isOpen?: boolean;
  propertyTypeDrowdownOption?: 'properties-for-sale' | 'properties-to-rent';
  apiPropSearch?: {
    baseUrl: string;
    endpoint: string;
  };
  open?: boolean;
  onClose?: () => void;
}

function LibSearchPropertyBox({
  isOpen = false,
  propertyTypeDrowdownOption,
  apiPropSearch = {
    baseUrl: '',
    endpoint: ''
  },
  open,
  onClose
}: LibSearchPropertyBoxProps) {
  const isDesktopView = useMediaQuery('(min-width:900px)');
  const desktopViewForDraw = useMediaQuery('(min-width:1200px)');

  const tabsData = isDesktopView ? tabsDataDesktop : tabsDataMobile;

  const [activeTab, setActiveTab] = useState('location');

  const [, setLocation] = useState(null);
  const [, setIsWaitingforNearMeAPI] = useState(false);
  const [webNameForNearBy, setWebNameForNearBy] = useState(null);
  const [showSearchTabs, setShowSearchTabs] = useState(false);
  const [searchTab, setTab] = useState('properties-for-sale');

  /* Decides which tab to show on page load */
  useEffect(() => {
    if (searchTab.length >= 1) return;

    setTab(
      propertyTypeDrowdownOption ||
        getLastSearchHistory() ||
        'properties-for-sale'
    );
  }, []);

  const getLocation = () => {
    if (navigator.geolocation) {
      setIsWaitingforNearMeAPI(true);
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          setLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
          });
          const response = await getPropertiesNearByCord(
            position.coords.latitude,
            position.coords.longitude
          );

          setWebNameForNearBy(response.response?.webName);
          setIsWaitingforNearMeAPI(false);
        },
        () => {
          setIsWaitingforNearMeAPI(false);
        }
      );
    } else {
      // Geolocation is not supported by this browser
      setIsWaitingforNearMeAPI(false);
    }
  };

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    if (newValue === 'nearMe') {
      getLocation();
    }

    setActiveTab(newValue);
  };

  const clearSessionStorage = () => {
    sessionStorage.removeItem('isDrawSearch');
    sessionStorage.removeItem('isLocationSearch');
    sessionStorage.removeItem('isRadiusTypeSearch');
    sessionStorage.removeItem('radius');
    sessionStorage.removeItem('travel_locations');
    sessionStorage.removeItem('travel_places');
    sessionStorage.removeItem('isNearMeSearch');
  };

  const renderComponent = (tabId: string) => {
    switch (tabId) {
      case 'location':
        return (
          <SearchProperty
            api={apiPropSearch}
            propertyType={searchTab || 'properties-for-sale'}
            open={isOpen}
            onInputFocus={() => setShowSearchTabs(true)}
          />
        );
      default:
        return null;
    }
  };

  const tabsWithNumbers = {
    location: 0,
    map: 1,
    draw: 2,
    'travel-time': 3,
    school: 4,
    station: 5,
    nearMe: 6
  };

  return (
    <Box sx={styles.container} className="SearchPropertyBox-root">
      <Tabs
        value={searchTab}
        onChange={(_, selection) => {
          setTab(selection);
          setLastSearchHistory({ search_type: selection });
        }}
      >
        <Tab sx={styles.searchTab} label="Buy" value="properties-for-sale" />
        <Tab sx={styles.searchTab} label="Rent" value="properties-to-rent" />
      </Tabs>

      <Box
        sx={{
          ...styles.tabsContainer,
          display: isOpen || open ? 'flex' : 'none'
        }}
      >
        <Box>
          {tabsData.map(
            ({ value }: Tab) =>
              activeTab === `${value}` && (
                <Box key={value} aria-label={`tabPanel-${value}`}>
                  <Box sx={styles.centralBoxStyles}>
                    {renderComponent(value)}
                  </Box>
                </Box>
              )
          )}
        </Box>
        <Box
          sx={styles.internalTabsContainer}
          className={showSearchTabs ? 'visible' : ''}
        >
          <Tabs
            variant={isDesktopView ? 'standard' : 'scrollable'}
            scrollButtons="auto"
            allowScrollButtonsMobile
            aria-label="scrollable auto tabs"
            value={tabsWithNumbers[activeTab]}
            TabIndicatorProps={{
              style: {
                backgroundColor: 'transparent'
              }
            }}
            sx={styles.scrollableTabs}
          >
            {tabsData.map(({ label, value, icon, tag, href }: Tab) =>
              !desktopViewForDraw && value === 'draw' ? null : href ? (
                <Link
                  href={
                    tag !== 'nearMe'
                      ? href(searchTab)
                      : `${href(searchTab)}${webNameForNearBy || 'w1k-4af'}`
                  }
                  onClick={() => {
                    onClose?.();
                  }}
                  key={tag}
                  style={{ textDecoration: 'none' }}
                  role="tab"
                  aria-label={`tab-${label}`}
                >
                  <Box
                    key={value}
                    onClick={() => {
                      clearSessionStorage();

                      if (tag === 'map') {
                        sessionStorage.setItem(
                          'isMapSearch',
                          JSON.stringify({
                            category: 'map',
                            type:
                              searchTab === 'properties-for-sale'
                                ? 'buy'
                                : 'long let'
                          })
                        );
                      } else {
                        sessionStorage.removeItem('isMapSearch');
                      }

                      if (tag === 'tube' || tag === 'school') {
                        sessionStorage.setItem('isRadiusTypeSearch', 'true');
                      }

                      if (tag === 'nearMe') {
                        sessionStorage.setItem('isNearMeSearch', 'true');
                      }
                    }}
                    sx={getTabStyles(activeTab, value)}
                  >
                    <Box sx={styles.tabIcon}>{icon}</Box>
                    <Typography sx={styles.tabLabel}>{label}</Typography>
                  </Box>
                </Link>
              ) : (
                <Box
                  key={value}
                  onClick={(e) => handleChange(e, value)}
                  sx={getTabStyles(activeTab, value)}
                  aria-label={`tab-${label}`}
                  role="tab"
                >
                  <Box sx={styles.tabIcon}>{icon}</Box>
                  <Typography sx={styles.tabLabel}>{label}</Typography>
                </Box>
              )
            )}
          </Tabs>
        </Box>
      </Box>
      <ValuationBanner
        onLinkClick={() => {
          onClose?.();
        }}
      />
    </Box>
  );
}

export const SearchPropertyBox = React.memo(
  LibSearchPropertyBox
) as typeof LibSearchPropertyBox;

export default SearchPropertyBox;
