import Link from 'next/link';
import { Box, Typography } from '@mui/material';
import Button from '../Button';
import styles from './styles';

interface ValuationBannerProps {
  onLinkClick: () => void;
}

export default function ValuationBanner({
  onLinkClick
}: ValuationBannerProps): JSX.Element {
  return (
    <Box sx={styles.valuationBanner}>
      <Typography variant="body1" sx={styles.valuationBannerTitle}>
        Find out your home&apos;s sales or rental value
      </Typography>
      <Link href="/valuations" passHref onClick={onLinkClick}>
        <Button variant="primary" color="primary">
          Get a free valuation
        </Button>
      </Link>
    </Box>
  );
}
