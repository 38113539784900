import React from 'react';
import { SvgIcon } from '@mui/material';

const SvgSchool = (props) => (
  <SvgIcon {...props} title="School">
    <svg
      viewBox="0 0 15 12"
      fill="#000"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.333 6.787V8.66c0 .487.267.94.694 1.173l3.333 1.82c.4.22.88.22 1.28 0l3.333-1.82a1.34 1.34 0 0 0 .694-1.173V6.787l-4.027 2.2c-.4.22-.88.22-1.28 0l-4.027-2.2ZM6.36.347.74 3.413a.671.671 0 0 0 0 1.174l5.62 3.066c.4.22.88.22 1.28 0L13 4.727v3.94c0 .366.3.666.667.666.366 0 .666-.3.666-.666V4.393a.67.67 0 0 0-.346-.586L7.64.347a1.36 1.36 0 0 0-1.28 0Z"
        fill={props.fill}
      />
    </svg>
  </SvgIcon>
);

export default SvgSchool;
