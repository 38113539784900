import { type SxProps, type Theme } from '@mui/material';
import { lightTheme } from '../../theme/index';

const { palette } = lightTheme;

const BORDER_WIDTH = '0.063rem';

export const tabsStyles: SxProps<Theme> = ({ spacing }) => ({
  position: 'relative',
  minHeight: 'auto',
  '& .MuiTabs-flexContainer': {
    gap: spacing(0.5),
    maxWidth: 'none'
  },
  '& .MuiTabs-indicator': {
    visibility: 'hidden'
  },
  '& .MuiTabs-flexContainer:before': {
    display: 'block',
    position: 'absolute',
    inset: 'auto 0 0 0',
    zIndex: 1,
    content: '""',
    height: BORDER_WIDTH,
    backgroundColor: palette.secondary['600']
  }
});

export const tabStyles: SxProps<Theme> = ({ spacing }) => ({
  '&.MuiButtonBase-root': {
    color: palette.primary.main, // greenish
    backgroundColor: palette.grey['50'], // white
    borderWidth: `${BORDER_WIDTH} ${BORDER_WIDTH}  0 ${BORDER_WIDTH}`,
    borderStyle: 'solid',
    borderColor: 'rgb(0 0 0 / 12%)',
    borderTopLeftRadius: '6px',
    borderTopRightRadius: '6px',
    flex: 1,
    textTransform: 'none',
    paddingY: spacing(0.5),
    minHeight: 'auto',
    maxWidth: 'none',
    fontSize: '1rem',
    borderBottomColor: palette.secondary['600'],
    borderBottomWidth: BORDER_WIDTH,
    '&.Mui-selected': {
      backgroundColor: palette.lightishAqua.main,
      borderColor: `${palette.secondary['600']} ${palette.secondary['600']} transparent`,
      zIndex: 2
    }
  }
});
