import React from 'react';
import { SvgIcon } from '@mui/material';

const SvgMapMarker = (props) => (
  <SvgIcon {...props} title="MapMarker">
    <svg
      viewBox="0 0 12 16"
      fill="#000"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M6 0C2.683 0 0 2.504 0 5.6 0 9.8 6 16 6 16s6-6.2 6-10.4C12 2.504 9.317 0 6 0Zm0 7.6c-1.183 0-2.143-.896-2.143-2s.96-2 2.143-2c1.183 0 2.143.896 2.143 2s-.96 2-2.143 2Z" />
    </svg>
  </SvgIcon>
);

export default SvgMapMarker;
